/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSeat = /* GraphQL */ `
  query GetSeat($id: ID!) {
    getSeat(id: $id) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSeats = /* GraphQL */ `
  query ListSeats(
    $filter: ModelSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const seatsByDepartmentID = /* GraphQL */ `
  query SeatsByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seatsByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const seatsByRotationIDAndDepartmentID = /* GraphQL */ `
  query SeatsByRotationIDAndDepartmentID(
    $rotationID: ID!
    $departmentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seatsByRotationIDAndDepartmentID(
      rotationID: $rotationID
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const seatsByTraineeIDAndRotationID = /* GraphQL */ `
  query SeatsByTraineeIDAndRotationID(
    $traineeID: String!
    $rotationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seatsByTraineeIDAndRotationID(
      traineeID: $traineeID
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublishedSeat = /* GraphQL */ `
  query GetPublishedSeat($id: ID!) {
    getPublishedSeat(id: $id) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      review
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPublishedSeats = /* GraphQL */ `
  query ListPublishedSeats(
    $filter: ModelPublishedSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishedSeats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        review
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publishedSeatsByDepartmentID = /* GraphQL */ `
  query PublishedSeatsByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPublishedSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publishedSeatsByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        review
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publishedSeatsByRotationIDAndDepartmentID = /* GraphQL */ `
  query PublishedSeatsByRotationIDAndDepartmentID(
    $rotationID: ID!
    $departmentID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublishedSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publishedSeatsByRotationIDAndDepartmentID(
      rotationID: $rotationID
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        review
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publishedSeatsByTraineeIDAndRotationID = /* GraphQL */ `
  query PublishedSeatsByTraineeIDAndRotationID(
    $traineeID: String!
    $rotationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublishedSeatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publishedSeatsByTraineeIDAndRotationID(
      traineeID: $traineeID
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        preferencePriority
        traineeID
        review
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrainee = /* GraphQL */ `
  query GetTrainee($id: String!) {
    getTrainee(id: $id) {
      id
      type
      traineeIndex
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      name
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          review
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        endDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cohortsIndex
        averagePreference
        deadline
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        visible
        createdAt
        updatedAt
        __typename
      }
      siteID
      site {
        id
        name
        twinSiteID
        wildcard
        createdAt
        updatedAt
        __typename
      }
      manualOverrideID
      manualOverride {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        siteID
        groupID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        visibleToTrainees
        notDecidedIndicator
        twinDepartmentID
        createdAt
        updatedAt
        __typename
      }
      ownerID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        siteID
        groupID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        visibleToTrainees
        notDecidedIndicator
        twinDepartmentID
        createdAt
        updatedAt
        __typename
      }
      traineeDiary
      hrComments
      firstSeatSupervisor
      secondSeatSupervisor
      thirdSeatSupervisor
      fourthSeatSupervisor
      firstSeatSupervisorHR
      secondSeatSupervisorHR
      thirdSeatSupervisorHR
      fourthSeatSupervisorHR
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTrainees = /* GraphQL */ `
  query ListTrainees(
    $id: String
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrainees(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        traineeIndex
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        email
        cohortID
        cohort {
          id
          type
          startDate
          endDate
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          cohortsIndex
          averagePreference
          deadline
          numberOfFirstPreferences
          numberOfSecondPreferences
          numberOfThirdPreferences
          numberOfFourthPreferences
          numberOfFifthPreferences
          numberOfSixthPreferences
          numberOfNoPreferences
          numberOfManualOverrides
          published
          visible
          createdAt
          updatedAt
          __typename
        }
        siteID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        desiredGraduatingSeat {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        traineeDiary
        hrComments
        firstSeatSupervisor
        secondSeatSupervisor
        thirdSeatSupervisor
        fourthSeatSupervisor
        firstSeatSupervisorHR
        secondSeatSupervisorHR
        thirdSeatSupervisorHR
        fourthSeatSupervisorHR
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const traineesByTypeAndTraineeIndex = /* GraphQL */ `
  query TraineesByTypeAndTraineeIndex(
    $type: String!
    $traineeIndex: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineesByTypeAndTraineeIndex(
      type: $type
      traineeIndex: $traineeIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        traineeIndex
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        email
        cohortID
        cohort {
          id
          type
          startDate
          endDate
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          cohortsIndex
          averagePreference
          deadline
          numberOfFirstPreferences
          numberOfSecondPreferences
          numberOfThirdPreferences
          numberOfFourthPreferences
          numberOfFifthPreferences
          numberOfSixthPreferences
          numberOfNoPreferences
          numberOfManualOverrides
          published
          visible
          createdAt
          updatedAt
          __typename
        }
        siteID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        desiredGraduatingSeat {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        traineeDiary
        hrComments
        firstSeatSupervisor
        secondSeatSupervisor
        thirdSeatSupervisor
        fourthSeatSupervisor
        firstSeatSupervisorHR
        secondSeatSupervisorHR
        thirdSeatSupervisorHR
        fourthSeatSupervisorHR
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const traineesByTraineeIndex = /* GraphQL */ `
  query TraineesByTraineeIndex(
    $traineeIndex: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineesByTraineeIndex(
      traineeIndex: $traineeIndex
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        traineeIndex
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        email
        cohortID
        cohort {
          id
          type
          startDate
          endDate
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          cohortsIndex
          averagePreference
          deadline
          numberOfFirstPreferences
          numberOfSecondPreferences
          numberOfThirdPreferences
          numberOfFourthPreferences
          numberOfFifthPreferences
          numberOfSixthPreferences
          numberOfNoPreferences
          numberOfManualOverrides
          published
          visible
          createdAt
          updatedAt
          __typename
        }
        siteID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        desiredGraduatingSeat {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        traineeDiary
        hrComments
        firstSeatSupervisor
        secondSeatSupervisor
        thirdSeatSupervisor
        fourthSeatSupervisor
        firstSeatSupervisorHR
        secondSeatSupervisorHR
        thirdSeatSupervisorHR
        fourthSeatSupervisorHR
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const traineesByCohortIDAndName = /* GraphQL */ `
  query TraineesByCohortIDAndName(
    $cohortID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineesByCohortIDAndName(
      cohortID: $cohortID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        traineeIndex
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        email
        cohortID
        cohort {
          id
          type
          startDate
          endDate
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          cohortsIndex
          averagePreference
          deadline
          numberOfFirstPreferences
          numberOfSecondPreferences
          numberOfThirdPreferences
          numberOfFourthPreferences
          numberOfFifthPreferences
          numberOfSixthPreferences
          numberOfNoPreferences
          numberOfManualOverrides
          published
          visible
          createdAt
          updatedAt
          __typename
        }
        siteID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        desiredGraduatingSeat {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        traineeDiary
        hrComments
        firstSeatSupervisor
        secondSeatSupervisor
        thirdSeatSupervisor
        fourthSeatSupervisor
        firstSeatSupervisorHR
        secondSeatSupervisorHR
        thirdSeatSupervisorHR
        fourthSeatSupervisorHR
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const traineesBySiteIDAndName = /* GraphQL */ `
  query TraineesBySiteIDAndName(
    $siteID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineesBySiteIDAndName(
      siteID: $siteID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        traineeIndex
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        name
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        email
        cohortID
        cohort {
          id
          type
          startDate
          endDate
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          cohortsIndex
          averagePreference
          deadline
          numberOfFirstPreferences
          numberOfSecondPreferences
          numberOfThirdPreferences
          numberOfFourthPreferences
          numberOfFifthPreferences
          numberOfSixthPreferences
          numberOfNoPreferences
          numberOfManualOverrides
          published
          visible
          createdAt
          updatedAt
          __typename
        }
        siteID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        manualOverrideID
        manualOverride {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        ownerID
        litigationSeatCompleted
        bankingSeatCompleted
        desiredGraduatingSeatID
        desiredGraduatingSeat {
          id
          type
          name
          mustBeFilled
          numberOfSeatsAvailable
          numberOfSeatsReservedForSecondYears
          numberOfSeatsReservedForFirstYears
          Preferences {
            items {
              id
              departmentID
              rotationID
              priority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          Seats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          PublishedSeats {
            items {
              id
              departmentID
              rotationID
              preferencePriority
              traineeID
              review
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          siteID
          groupID
          site {
            id
            name
            twinSiteID
            wildcard
            createdAt
            updatedAt
            __typename
          }
          departmentInfo
          litigationDepartment
          bankingDepartment
          visibleToTrainees
          notDecidedIndicator
          twinDepartmentID
          createdAt
          updatedAt
          __typename
        }
        traineeDiary
        hrComments
        firstSeatSupervisor
        secondSeatSupervisor
        thirdSeatSupervisor
        fourthSeatSupervisor
        firstSeatSupervisorHR
        secondSeatSupervisorHR
        thirdSeatSupervisorHR
        fourthSeatSupervisorHR
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPreference = /* GraphQL */ `
  query GetPreference($id: ID!) {
    getPreference(id: $id) {
      id
      departmentID
      rotationID
      priority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPreferences = /* GraphQL */ `
  query ListPreferences(
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        departmentID
        rotationID
        priority
        traineeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const preferencesByDepartmentID = /* GraphQL */ `
  query PreferencesByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        priority
        traineeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const preferencesByRotationID = /* GraphQL */ `
  query PreferencesByRotationID(
    $rotationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByRotationID(
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        priority
        traineeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const preferencesByTraineeIDAndRotationID = /* GraphQL */ `
  query PreferencesByTraineeIDAndRotationID(
    $traineeID: String!
    $rotationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferencesByTraineeIDAndRotationID(
      traineeID: $traineeID
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        rotationID
        priority
        traineeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      type
      name
      mustBeFilled
      numberOfSeatsAvailable
      numberOfSeatsReservedForSecondYears
      numberOfSeatsReservedForFirstYears
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          review
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      siteID
      groupID
      site {
        id
        name
        twinSiteID
        wildcard
        createdAt
        updatedAt
        __typename
      }
      departmentInfo
      litigationDepartment
      bankingDepartment
      visibleToTrainees
      notDecidedIndicator
      twinDepartmentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        siteID
        groupID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        visibleToTrainees
        notDecidedIndicator
        twinDepartmentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const departmentsByIdAndName = /* GraphQL */ `
  query DepartmentsByIdAndName(
    $id: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByIdAndName(
      id: $id
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        siteID
        groupID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        visibleToTrainees
        notDecidedIndicator
        twinDepartmentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const departmentsByTypeAndName = /* GraphQL */ `
  query DepartmentsByTypeAndName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        siteID
        groupID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        visibleToTrainees
        notDecidedIndicator
        twinDepartmentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const departmentsBySiteIDAndName = /* GraphQL */ `
  query DepartmentsBySiteIDAndName(
    $siteID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsBySiteIDAndName(
      siteID: $siteID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        siteID
        groupID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        visibleToTrainees
        notDecidedIndicator
        twinDepartmentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const departmentsByGroupIDAndName = /* GraphQL */ `
  query DepartmentsByGroupIDAndName(
    $groupID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByGroupIDAndName(
      groupID: $groupID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        siteID
        groupID
        site {
          id
          name
          twinSiteID
          wildcard
          createdAt
          updatedAt
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        visibleToTrainees
        notDecidedIndicator
        twinDepartmentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRotation = /* GraphQL */ `
  query GetRotation($id: ID!) {
    getRotation(id: $id) {
      id
      type
      startDate
      endDate
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          review
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cohortsIndex
      averagePreference
      deadline
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFifthPreferences
      numberOfSixthPreferences
      numberOfNoPreferences
      numberOfManualOverrides
      published
      visible
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRotations = /* GraphQL */ `
  query ListRotations(
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        startDate
        endDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cohortsIndex
        averagePreference
        deadline
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        visible
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rotationsByIdAndStartDate = /* GraphQL */ `
  query RotationsByIdAndStartDate(
    $id: ID!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rotationsByIdAndStartDate(
      id: $id
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        startDate
        endDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cohortsIndex
        averagePreference
        deadline
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        visible
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rotationsByTypeAndStartDate = /* GraphQL */ `
  query RotationsByTypeAndStartDate(
    $type: String!
    $startDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRotationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rotationsByTypeAndStartDate(
      type: $type
      startDate: $startDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        startDate
        endDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            review
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cohortsIndex
        averagePreference
        deadline
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        visible
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDepartmentStatistic = /* GraphQL */ `
  query GetDepartmentStatistic($id: ID!) {
    getDepartmentStatistic(id: $id) {
      id
      rotationID
      siteID
      departmentID
      departmentName
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFifthPreferences
      numberOfSixthPreferences
      numberOfFirstPreferencesAssigned
      numberOfSecondPreferencesAssigned
      numberOfThirdPreferencesAssigned
      numberOfFourthPreferencesAssigned
      numberOfFifthPreferencesAssigned
      numberOfSixthPreferencesAssigned
      numberOfNoPreferencesAssigned
      numberOfManualOverrides
      numberOfSeatsAssigned
      numberOfSeatsAvailable
      numberOfFirstYearsAssigned
      numberOfSecondYearsAssigned
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDepartmentStatistics = /* GraphQL */ `
  query ListDepartmentStatistics(
    $filter: ModelDepartmentStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartmentStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rotationID
        siteID
        departmentID
        departmentName
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfFirstPreferencesAssigned
        numberOfSecondPreferencesAssigned
        numberOfThirdPreferencesAssigned
        numberOfFourthPreferencesAssigned
        numberOfFifthPreferencesAssigned
        numberOfSixthPreferencesAssigned
        numberOfNoPreferencesAssigned
        numberOfManualOverrides
        numberOfSeatsAssigned
        numberOfSeatsAvailable
        numberOfFirstYearsAssigned
        numberOfSecondYearsAssigned
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const departmentStatisticsByRotationIDAndDepartmentName = /* GraphQL */ `
  query DepartmentStatisticsByRotationIDAndDepartmentName(
    $rotationID: ID!
    $departmentName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentStatisticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentStatisticsByRotationIDAndDepartmentName(
      rotationID: $rotationID
      departmentName: $departmentName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rotationID
        siteID
        departmentID
        departmentName
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfFirstPreferencesAssigned
        numberOfSecondPreferencesAssigned
        numberOfThirdPreferencesAssigned
        numberOfFourthPreferencesAssigned
        numberOfFifthPreferencesAssigned
        numberOfSixthPreferencesAssigned
        numberOfNoPreferencesAssigned
        numberOfManualOverrides
        numberOfSeatsAssigned
        numberOfSeatsAvailable
        numberOfFirstYearsAssigned
        numberOfSecondYearsAssigned
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      name
      twinSiteID
      wildcard
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        twinSiteID
        wildcard
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTraineeComments = /* GraphQL */ `
  query GetTraineeComments($id: ID!) {
    getTraineeComments(id: $id) {
      id
      rotationID
      traineeID
      comment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTraineeComments = /* GraphQL */ `
  query ListTraineeComments(
    $filter: ModelTraineeCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTraineeComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rotationID
        traineeID
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const traineeCommentsByRotationID = /* GraphQL */ `
  query TraineeCommentsByRotationID(
    $rotationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineeCommentsByRotationID(
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rotationID
        traineeID
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const traineeCommentsByTraineeIDAndRotationID = /* GraphQL */ `
  query TraineeCommentsByTraineeIDAndRotationID(
    $traineeID: String!
    $rotationID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTraineeCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    traineeCommentsByTraineeIDAndRotationID(
      traineeID: $traineeID
      rotationID: $rotationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rotationID
        traineeID
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      type
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const groupsByTypeAndName = /* GraphQL */ `
  query GroupsByTypeAndName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupsByTypeAndName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
