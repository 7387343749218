/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://nye9rp86j9.execute-api.eu-west-2.amazonaws.com/cms",
            "region": "eu-west-2"
        },
        {
            "name": "assignSeats",
            "endpoint": "https://3c18qo3kx0.execute-api.eu-west-2.amazonaws.com/cms",
            "region": "eu-west-2"
        },
        {
            "name": "publishSeats",
            "endpoint": "https://td8dssinc4.execute-api.eu-west-2.amazonaws.com/cms",
            "region": "eu-west-2"
        },
        {
            "name": "reminderEmail",
            "endpoint": "https://e82vk32iwa.execute-api.eu-west-2.amazonaws.com/cms",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://rmyi4kqjrbc3jiloku2h2naq4i.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:f2db11f3-3f5d-4e93-a9d8-b9da836164e8",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_8an0dhzNX",
    "aws_user_pools_web_client_id": "36dii4nrefpqng6qfllk6b365o",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "optiseatb36133a8b081486898e91560fcd6852c7ddd8-cms",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
